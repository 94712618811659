(function ($) {
    "use strict";



    /*==================================================================
    [ Focus Contact2 ]*/
    $('.input100').each(function () {
        $(this).on('blur', function () {
            if ($(this).val().trim() != "") {
                $(this).addClass('has-val');
            } else {
                $(this).removeClass('has-val');
            }
        })
    })


    /*==================================================================
    [ Validate ]*/
    var input = $('.validate-input .input100');

    if($('input[name="agency_name"]').length > 0){
        // console.log('load');
        $('input[name="agency_name"]').on("keyup change", function(e){
            let val = $(this).val();
            // if(val.match(/[^a-z,^A-Z,^0-9 ,\.]/) != null){
            if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
                $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
            }
            else{
                $(this)[0].setCustomValidity("");
            }
        });
    }

    if($('input[name="email"]').length > 0){
        $('input[name="email"]').on("input keyup change focusout mouseleave paste blur", function(e){
            console.log('sss');
            let val = $(this).val();
            $(this)[0].setCustomValidity("");
            $('.email_validation').html("");
            if(val != ''){
                if ($(this).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                    $(this)[0].setCustomValidity('Valid email is required: ex@abc.xyz');
                    $('.email_validation').html("Invalid email address.");
                }
                else{
                    $(this)[0].setCustomValidity("");
                    $('.email_validation').html("");
                }
            }
        });
        // $('input[name="email"]').on("focusout mouseleave", function(e){
        //     let val = $(this).val();
        //     $(this)[0].setCustomValidity("");
        //     $('.email_validation').html("");
        //     if(val != ''){
        //         if ($(this).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
        //             $(this)[0].setCustomValidity('Valid email is required: ex@abc.xyz');
        //             $('.email_validation').html("Invalid email address.");
        //         }
        //         else{
        //             $(this)[0].setCustomValidity("");
        //             $('.email_validation').html("");
        //         }
        //     }
        // });
    }

    $('.validate-form').on('submit', function () {
        var check = true;

        for (var i = 0; i < input.length; i++) {
            if (validate(input[i]) == false) {
                // showValidate(input[i]);
                check = false;
            }
        }

        return check;
    });


    $('.validate-form .input100').each(function () {
        $(this).focus(function () {
            hideValidate(this);
        });
    });

    function validate(input) {
        if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
            if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                $(input)[0].setCustomValidity('Valid email is required: ex@abc.xyz');
                return false;
            }
        } else {
            if ($(input).val().trim() == '') {
                return false;
            }
        }
    }

    function showValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).removeClass('alert-validate');
    }

    // if($('.validate_email').hasClass('custom-validate')) {
    //     let validation = String($('.validate_email').val())
    //                             .toLowerCase()
    //                             .match(
    //                                 /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //                             );
    //     if(validation){
    //         invalid_count['email'] = 2;
    //         $('.email_validation').html("");
    //     } else {
    //         invalid_count['email'] = 1;
    //         $('.email_validation').html("Invalid email address.");
    //     }
    // }


})(jQuery);
